import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';

export interface PaymentDestinationResponse {
  dataSourceId: string;
  clientId: string;
  billingGroupId: number;
  guarantorId: number;
  personId: number;
  locationId: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentDestinationService {
  private readonly logger: ILogger;
  endPointBase: string = '/PaymentDestination';

  response!: PaymentDestinationResponse | null;

  complete: boolean = false;

  private responseSubject =
    new BehaviorSubject<PaymentDestinationResponse | null>(this.response);
  response$ = this.responseSubject.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory
  ) {
    this.logger = loggerFactory('PaymentDestinationService');
  }

  fetchPaymentDestination() {
    this.http
      .post<PaymentDestinationResponse>(
        environment.apiUrl + this.endPointBase,
        {},
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<PaymentDestinationResponse>) => {
          if (res.body) {
            this.logger.debug('Received Response', { body: res.body });
            this.response = res.body;
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
      });
  }

  getPaymentDestination() {
    return this.response; // Return data stored in the service
  }
}
