import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { isLocalDevelopment } from '../../helpers/helpers';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';
import {
  PaymentServiceService,
  paymentSuccessData,
} from '../../payments/payment/services/payment-service.service';
import { BalanceService } from '../balance/balance.service';
import { PaymentAccessTokenService } from '../payment-access-token/payment-access-token.service';
import { PaymentDestinationService } from '../payment-destination/payment-destination.service';

interface IFormCallback {
  (): void;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentLibraryService {
  private readonly logger: ILogger;

  paymentDestinationService = inject(PaymentDestinationService);
  paymentAccessTokenService = inject(PaymentAccessTokenService);
  paymentService = inject(PaymentServiceService);
  balanceService = inject(BalanceService);
  endPointBase: string = '/PaymentLibrary';

  response: string = '';
  complete: boolean = false;

  scriptAddedToHead = false;

  responseSubject = new BehaviorSubject<any>(this.response); // eslint-disable-line @typescript-eslint/no-explicit-any
  response$ = this.responseSubject.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory
  ) {
    this.logger = loggerFactory('PaymentLibraryService');
  }

  fetchPaymentLibrary() {
    this.http
      .post(
        environment.apiUrl + this.endPointBase,
        {},
        {
          observe: 'response',
          responseType: 'text',
        }
      )
      .subscribe({
        next: (res: HttpResponse<string>) => {
          if (res.status === 200 && res.body) {
            this.response = res.body;
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
        error: (error: unknown) => {
          this.logger.error(
            'Failed to get payment module library',
            undefined,
            error
          );
        },
      });
  }

  getPaymentLibrary() {
    return this.response; // Return data stored in the service
  }

  generatePaymentLibraryScriptTag(scriptContent: string) {
    const id = 'payment-module-library-script';

    if (document.getElementById(id) === null) {
      if (!this.scriptAddedToHead) {
        this.logger.debug('Appended script tag');
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.id = id;
        scriptElement.text = scriptContent;
        document.head.appendChild(scriptElement);
        this.scriptAddedToHead = true;
      }
    }
  }

  /* istanbul ignore next we should test this but its killing dev time right now, sorry future me */
  configureForm(
    type: string,
    pmForm: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    options: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    onInitCallback: IFormCallback = () => {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    const charges = this.balanceService.getBalance().openCharges;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pmCharges: any[] = [];
    let paymentType;
    switch (type) {
      case 'bill-pay':
        paymentType = 'PatientPostPay';
        break;
      case 'payment-plan':
        paymentType = 'PatientPaymentPlan';
        break;
      default:
        paymentType = 'PatientPostPay';
    }

    charges.forEach(charge => {
      pmCharges.push({
        id: charge.chargeId,
        discount: 0,
        discountCode: '',
        isCredit: false,
        paidInFull: true,
        paymentAmount: charge.balance,
        originalAmount: charge.baseFee,
      });
    });
    const optionsCopy = options;
    pmForm = pmForm
      // TODO FIX this
      .OnSuccess((data: paymentSuccessData) => {
        this.paymentService.paymentSuccessData = data;
        this.paymentService.paymentSuccessSubject.next(data);
        this.paymentService.nextPage();
      })
      .WithCharges(pmCharges);

    if (type === 'payment-plan') {
      pmForm.IncludePlan({
        monthlyPaymentAmount: optionsCopy.additionalFields.paymentAmount,
        initialContractAmount: Math.min(
          optionsCopy.additionalFields.paymentAmount *
            optionsCopy.additionalFields.numberOfPayments,
          optionsCopy.guarantorBalance
        ),
        firstInstallmentDate: optionsCopy.startDate,
      });
    }

    if (type === 'payment-plan') {
      pmForm.WithWebHook(
        isLocalDevelopment() ? '' : `${environment.apiUrl}/LogPayment/PaymentPlan`
      )
    }
    else
    {
      pmForm.WithWebHook(
        isLocalDevelopment() ? '' : `${environment.apiUrl}/LogPayment/Payment`
      )
    }

    pmForm
      .WithChargeType(paymentType)
      .WithAppName('PXP')
      .ConfigurePatientInternal({
        dataSourceId:
          this.paymentDestinationService.getPaymentDestination()?.dataSourceId,
        clientId:
          this.paymentDestinationService.getPaymentDestination()?.clientId,
        billingGroupId:
          this.paymentDestinationService.getPaymentDestination()
            ?.billingGroupId,
        entityId: '',
        guarantorId:
          this.paymentDestinationService.getPaymentDestination()?.guarantorId,
        locationId:
          this.paymentDestinationService.getPaymentDestination()?.locationId,
        patientId: 0,
      })
      .OnVerificationRequest(() => {
        return this.paymentAccessTokenService.getPaymentAccessToken();
      })
      .OnInitialized(onInitCallback);

    if (optionsCopy.paymentAmount > 0) {
      pmForm.IncludePayment({ paymentAmount: optionsCopy.paymentAmount });
    }

    if (
      optionsCopy.originatingUserInfo &&
      optionsCopy.originatingUserInfo.originatingUserId &&
      optionsCopy.originatingUserInfo.originatingUserTypeId
    ) {
      pmForm = pmForm.WithOriginatingInformation(
        optionsCopy.originatingUserInfo.originatingUserId,
        optionsCopy.originatingUserInfo.originatingUserTypeId
      );
    }

    return pmForm;
  }
}
